export function SimpleHttp(method: string, url: string, callback: Function,  async: boolean = true, body?: any, errorHandler?: Function ) : void {
    if (method !== 'GET' && method !== 'HEAD' && method !== 'POST') {
        console.error('Only GET, POST and HEAD are supported for now');
        return;
    }
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
        // TODO: Add error handling
        if (xhttp.readyState === 4) {
            if(xhttp.status === 200) {
                // OK
                if( method == 'HEAD'){
                    callback();
                }
                else {
                    try {
                        callback(JSON.parse(xhttp.response));
                    }
                    catch(e) {
                        // JSON parsing failed
                        callback();
                    }
                }
            }
            else {
                if (errorHandler) {
                    errorHandler(xhttp.status);
                }
            }
        }
    };


    xhttp.open(method, url, async);

    //safari is not always setting content-type
    xhttp.setRequestHeader('Accept', 'application/json');
    xhttp.setRequestHeader('Content-Type', 'application/json');
    !!body ? xhttp.send(JSON.stringify(body)) : xhttp.send();

}
