import {NavigationEvent} from './models/navigation-event';
export const navigationService = (() => {
    const voters: Array<Function> = [];
    (window as any).ipb.registerNavigationVoter = (voter: (event: NavigationEvent) => boolean) => navigationService.registerNavigationVoter(voter);
    
    return {
        ignoreHashChange: false,
        canNavigate: (proceedFn: Function, cancelFn: Function): boolean => {
            let continueNavigation = true;
            if (!navigationService.ignoreHashChange) {
                const newNavigationEvent: NavigationEvent = {
                    doProceed: () => {
                        proceedFn();
                    },
                    doCancel: () => {
                        cancelFn();
                    }
                };
                for(let voter of voters) {
                    continueNavigation = continueNavigation && !!voter(newNavigationEvent);
                }
            }
            navigationService.ignoreHashChange = false;
            return continueNavigation;
        },
        registerNavigationVoter: (voter: (event: NavigationEvent) => boolean): void => {
            voters.push(voter);
        }
    };
})();
