export const frontendsLoadedService = (() => {
   
    const activatedListeners: Array<Function> = [];

    (window as any).ipb.onFrontendsLoaded = (callback: Function) => frontendsLoadedService.setActivatedListeners(callback);

    return {
        // notify the correct widget that he gets shown
        notifyFrontendsLoaded: () => {
            activatedListeners.forEach((callback) => {
                callback();
            });
        },
        setActivatedListeners: (callback: Function): void => {
            activatedListeners.push(callback);
        }
    };
})();   
