class GlobalService {
    private globalServices: any = {};

    constructor() {
        this.init();
    }
    
    private init(): void {
        (window as any).globalServices = this.globalServices;
    }

    public register<T>(serviceName: string, serviceInstance: T): void {        
        this.globalServices[serviceName] = serviceInstance;
    }
}

export const globalService = new GlobalService();
