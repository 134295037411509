export class LoginService {
    private _loginModel: any;
    public get loginModel(): any {
        return this._loginModel;
    }
    public set loginModel(value: any) {
        this._loginModel = value;
    }
    constructor() {
        const w = window as any;
        if (w.globalServices && w.globalServices.LoginModel) {
            // Ok Login Model Exists
            this.loginModel = w.globalServices.LoginModel;
        } else if (w.globalServices && w.globalServices.LegacyPubsubService && w.globalServices.LegacyPubsubService.subscribe) {
            // Old Angular1 global services will notify us when they are instantiated
            w.globalServices.LegacyPubsubService.subscribe('OLB_ANGULAR1_GLOBAL_SERVICE_LOADED', (serviceName: string) => {
                if (serviceName === 'LoginModel') {
                    this.loginModel = w.globalServices.LoginModel;
                }
            });
        }
    }

    public getLoginServiceParam(): string {
       return this.loginModel.getServiceValueFromStorage();
    }

    public removeLoginServiceParamFromStorage(): void {
        this.loginModel.removeServiceValueFromStorage();
    }

    public getItsMeLoginURL(): string {
        return this.loginModel.cas_itsme_login_url;
    }
}
