import { SimpleHttp } from './simple-http';
import { EnvironmentSettings } from './models/environment-settings';
import { ENDPOINTS } from '../constants/endpoints';

export class GlobalEnvironmentSettingsService {

    private callbackFnArray: Array<Function> = [];
    private environmentSettings: EnvironmentSettings;

    constructor() {
        this.getEnvironmentSettings();
    }

    public onLoad(callbackFn: Function): void {
        this.callbackFnArray.push(callbackFn);
        if (this.environmentSettings) {
            callbackFn(this.environmentSettings);
        }
    }

    private getEnvironmentSettings(): void {
        SimpleHttp('GET', ENDPOINTS.environmentSettings, (environmentSettings: EnvironmentSettings) => {
            this.setEnvironmentSettings(environmentSettings);
        }, true, null, (statusCode: number) => {
            // throw error or redirect
        });
    }

    private setEnvironmentSettings(envSettings: EnvironmentSettings): void {
        if (envSettings && envSettings.environment) {
            this.environmentSettings = envSettings;
            this.callbackFnArray.forEach((callback) => callback(this.environmentSettings));
        }
    }

}
