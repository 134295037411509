export class Language {
    constructor(public code: string, public locale: string, public full: string) {

    }
}

export class LanguageEnum {
    public static NL: Language = new Language("nl", "nl-BE", "Nederlands");
    public static FR: Language = new Language("fr", "fr-BE", "Français");
}
