import { Language, LanguageEnum } from "./models/language.enum";

export class AnonymousLanguageService {

    public static LOGIN_LANG_STORAGE: string = "OLB_LOGIN_LANG";
    public static LOGIN_LANG_PARAM: string = "language";

    private language: Language;
    private callbackFnArray: Array<Function> = [];

    constructor() {
        this.detectLanguage();
    }

    public onLanguageChange(callbackFn: Function): void {
        this.callbackFnArray.push(callbackFn);
        if (this.language) {
            callbackFn(this.language.locale);
        }
    }

    public changeLanguage(language: string): void {
        this.language = this.resolveLang(language);
        this.saveLanguageToStorage();
        this.publishLanguageChange();
    }

    public detectLanguage(): void {
        this.getLanguage();
        this.saveLanguageToStorage();
        this.publishLanguageChange();
    }

    private getLanguage(): void {
        let language: string = this.getLanguageFromQueryParameter()
                || this.getLoginLanguageFromStorage()
                || this.getLanguageFromHTML5Spec()
                || this.getLanguageFromNonStandardBrowserProperties();

        this.language = this.resolveLang(language);
    }

    private resolveLang(lang: string): Language {
        if (!lang) {
            return LanguageEnum.NL;
        }
        return lang.toLowerCase().indexOf(LanguageEnum.FR.code) > -1 ? LanguageEnum.FR : LanguageEnum.NL;
    }

    private saveLanguageToStorage(): void {
        localStorage.setItem(AnonymousLanguageService.LOGIN_LANG_STORAGE, "" + this.language.locale);
    }

    private publishLanguageChange(): void {
        this.callbackFnArray.forEach((callback) => callback(this.language.locale));
    }

    private getLanguageFromQueryParameter(): string {
        let language: string;
        let locationSplitted: Array<string> = decodeURIComponent(window.location.href).split("?");
        if (locationSplitted.length === 2) {
            let parameters: Array<string> = locationSplitted[1].split("&");

            parameters.forEach((param: string) => {
                let value: Array<string> = param.split("=");
                switch (value[0]) {
                    case AnonymousLanguageService.LOGIN_LANG_PARAM:
                        language = value[1];
                        break;
                    default:
                        break;
                }
            });
        }

        return language;
    }


    private getLanguageFromHTML5Spec(): string {
        let language: string;
        const languages: Array<string> = (<any>window.navigator).languages;

        if (languages) {
            languages.forEach(lang => {
                if (!language) {
                    language = lang.indexOf(LanguageEnum.FR.code) > -1 ? LanguageEnum.FR.code :
                        lang.indexOf(LanguageEnum.NL.code) > -1 ? LanguageEnum.NL.code : null;
                }
            });
        }

        return language;
    }

    private getLanguageFromNonStandardBrowserProperties(): string {
        let language: string = (<any>window.navigator).language ||
            (<any>window.navigator).browserLanguage ||
            (<any>window.navigator).systemLanguage ||
            (<any>window.navigator).userLanguage;

        return language;
    }

    private getLoginLanguageFromStorage(): string {
        return window.localStorage.getItem(AnonymousLanguageService.LOGIN_LANG_STORAGE);
    }



}
