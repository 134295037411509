export class WebtrekkService {
    constructor() {
        this.setOptoutCookie();
    }

    /**
     * Method to set webtrkkOptOut cookie, Based on cookie acceptance in PWS site.
     * Cookie value representation - XY.
     * X - Analytics (webtrekk) cookie, Y - Commerical Cookie
     * 1 - Accepted, 0 - rejected.
     */
    private setOptoutCookie(): void {
        const cookieAcceptanceStatus: string = this.checkUserCookieAcceptance();
        if (!cookieAcceptanceStatus || cookieAcceptanceStatus[0] !== "1") {
            document.cookie = "webtrekkOptOut=1;path=/";
        } else {
            // delete the cookie, if cookie is accepted.
            document.cookie = "webtrekkOptOut=1;path=/;max-age=0";
        }
    }

    /**
     * Method to get the value of cookie CookiePolicy.
     * @returns {string}
     */
    private checkUserCookieAcceptance(): string {
        const userAcceptanceCookieName = "cookiePolicy";
        const cookieArr: Array<string> = document.cookie.split(";")
        for (let i = 0; i < cookieArr.length; i++) {
            let cookiePair = cookieArr[i].split("=");
            if (userAcceptanceCookieName === cookiePair[0].trim()) {
                return cookiePair[1];
            }
        }
    }
}
