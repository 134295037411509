export const widgetActivationService = (() => {
   
    const activatedListeners = new Map();
    const deactivatedListeners = new Map();
    
    const findClosestElement = (startElement: Node, widgetElem: Node) => {
        let el = startElement;
        do {
            if ((<any>el) && el === widgetElem) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };

    (window as any).ipb.onWidgetActivation = (widgetElementRef: Element, callback: Function) => widgetActivationService.setActivatedListeners(widgetElementRef, callback);
    (window as any).ipb.onWidgetDeactivation = (widgetElementRef: Element, callback: Function) => widgetActivationService.setDeactivatedListeners(widgetElementRef, callback);

    return {
        // notify the correct widget that he gets shown
        notifyWidgetActivation: (widgetElem: Node) => {

            // scroll window to top if not at top if not already at top
            // TODO for always visible widgets like session out
            if (window.scrollY) {
                window.scroll(0, 0);  // reset the scroll position to the top left of the document.
            }
            activatedListeners.forEach((value) => {
                if (findClosestElement(value.element, widgetElem)) {
                    const cb = value.callback;
                    cb();
                }
            });
        },
        // notify the correct widget that he is hidden
        notifyWidgetDeactivation: (widgetElem: Node) => {
            deactivatedListeners.forEach((value) => {
                if (findClosestElement(value.element, widgetElem)) {
                    const cb = value.callback;
                    cb();
                }
            });
        },
        setActivatedListeners: (widgetElementRef: Element, callback: Function): void => {
            activatedListeners.set(widgetElementRef.localName, {element: widgetElementRef, callback: callback});
        },
        setDeactivatedListeners: (widgetElementRef: Element, callback: Function): void => {
            deactivatedListeners.set(widgetElementRef.localName, {element: widgetElementRef, callback: callback});
        }
    };
})();   
