import { globalService } from '../../../../global-service';
import { AnonymousLanguageService } from '../../../../services/anonymous-language-service';
import { GlobalEnvironmentSettingsService } from '../../../../services/global-environment-settings.service';
import { LegacyPubsubService } from '../../../../services/legacy-pubsub.service';
import { LoginService } from '../../../../services/login.service';
import { WebtrekkService } from '../../../../services/webtrekk.service';

export { stitch } from '../../../../stitch';

// initialize webtrekk service
new WebtrekkService;

// register global services
globalService.register('GlobalEnvironmentSettingsService', new GlobalEnvironmentSettingsService);
globalService.register('LegacyPubsubService', new LegacyPubsubService);
globalService.register('AnonymousLanguageService', new AnonymousLanguageService);
globalService.register('LoginService', new LoginService);
