interface ISubscribers { 
    [eventName: string]: Function[]
}

export class LegacyPubsubService {
   
    private subscribers:ISubscribers = {};

    public publish(eventName: string, data: any): void {
        // return if event is not subscribed
        if(!Array.isArray(this.subscribers[eventName])) {
            return;
        }
        // Publish an event, it will trigger for all
        this.subscribers[eventName].forEach((callback: Function) => {
            callback(data);
        });

    }

    public subscribe(eventName: string, callback: Function): void {
        if (!Array.isArray(this.subscribers[eventName])) {
            this.subscribers[eventName] = [];
        }
         // on subscribe push callback to subscribers[eventName]
        this.subscribers[eventName].push(callback);
    }

    public unsubscribe(eventName: string, callback: Function): void {
        if (Array.isArray(this.subscribers[eventName])) {
            this.subscribers[eventName] = this.subscribers[eventName].filter((fn) => fn != callback);
        }
    }
}
