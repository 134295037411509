import {StitchService} from './stitch-service';

class Stitch {

    private stitchService: StitchService;

    constructor() {
        this.stitchService = new StitchService();
        this.initialize();
    }

    private initialize(): void {
        window.addEventListener('hashchange', (event: HashChangeEvent) => {
                this.stitchService.onNavigated();
        });
        this.stitchService.onNavigated();
        /* Also update hash when navigating within one widget.
            Angular router does not trigger the hashchange event. */
        this.patchHistoryPushState();
    }

    private patchHistoryPushState() {
        const self = this;
        const pushState = window.history.pushState;
        window.history.pushState = function () {
            if (arguments[2].indexOf('/index#/messages') >= 0 ||
                arguments[2].indexOf('/index#/diy-form') >= 0 ||
                arguments[2].indexOf('/index#/payments') >= 0 ||
                arguments[2].indexOf('/index#/transactions') >= 0  // To trigger the update hash for transactions as it won't update on Angular router 
            ) {
                self.stitchService.updateHash(arguments[2]);
            }
            return pushState.apply(history, arguments);
        };
    }

}

export const stitch = new Stitch();
